import { useContext } from 'react';
import AuthContext from '../../../contexts/authContext';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { appPagesMenu, dashboardPagesMenu } from '../../../menu';
import getAppPagesAvailable from './util/GetAppPagesAvailable';
import rolesPagesAllowed, { rolesDashboardAllowed } from './util/RolesPagesAllowed';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { userData } = useContext(AuthContext);

	const userRoles = userData.roles?.map((role) => role.pivot.admin_role_id);
	const pagesAvailable = getAppPagesAvailable(userRoles, appPagesMenu, rolesPagesAllowed);
	const dashboardAvailable = getAppPagesAvailable(
		userRoles,
		dashboardPagesMenu,
		rolesDashboardAllowed,
	);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={dashboardAvailable} id='aside-dashboard' />
				<Navigation menu={pagesAvailable} id='aside-app-pages' />
			</AsideBody>
			<AsideFoot>
				<nav aria-label='aside-bottom-menu'></nav>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
