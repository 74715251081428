export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: {
			dashboardSales: {
				id: 'dashboardSales',
				text: 'Comercial',
				path: 'dashboard/sales',
				icon: 'DataSaverOff',
			},
			dashboardFinance: {
				id: 'dashboardFinance',
				text: 'Financeiro',
				path: 'dashboard/finance',
				icon: 'Analytics',
			},
		},
	},
};

export const appPagesMenu = {
	leads: {
		id: 'leads',
		text: 'Leads',
		path: 'leads',
		icon: 'Storefront',
		subMenu: null,
	},
	customers: {
		id: 'customers',
		text: 'Customers',
		path: 'customers',
		icon: 'People Alt',
		subMenu: null,
	},
	products: {
		id: 'products',
		text: 'Products',
		path: 'products',
		icon: 'Category',
		subMenu: {
			serviceNUN: {
				id: 'nun',
				text: 'NUN',
				path: 'products/nun',
				icon: 'Tty',
			},
			serviceSainte: {
				id: 'sainte',
				text: 'Sainte',
				path: 'products/sainte',
				icon: 'Phone Forwarded',
			},
		},
	},
	billing: {
		id: 'billing',
		text: 'Billing',
		path: 'billing',
		icon: 'Payments',
		subMenu: null,
	},
	reports: {
		id: 'reports',
		text: 'Reports',
		path: 'reports',
		icon: 'Leaderboard',
		subMenu: null,
	},
	registry: {
		id: 'registry',
		text: 'Registry',
		path: 'registry',
		icon: 'Group',
		subMenu: null,
	},
};

export const profilePage = {
	profile: {
		id: 'profile',
		text: 'Profile',
		path: 'profile',
		icon: 'User',
		subMenu: null,
	},
};
