export type RolesPagesAllowed = {
	admin: string[];
	commercial: string[];
	financial: string[];
	super_admin: string[];
	technical_support: string[];
};

const rolesPagesAllowed: RolesPagesAllowed = {
	admin: [
		'leads',
		'customers',
		'billing',
		'products',
		'serviceNUN',
		'serviceSainte',
		'reports',
		'customerDetail',
		'profile',
	],
	commercial: [
		'leads',
		'customers',
		'billing',
		'products',
		'serviceNUN',
		'serviceSainte',
		'reports',
		'customerDetail',
		'profile',
	],
	financial: [
		'leads',
		'customers',
		'billing',
		'products',
		'serviceNUN',
		'serviceSainte',
		'reports',
		'customerDetail',
		'profile',
	],
	super_admin: [
		'leads',
		'customers',
		'billing',
		'products',
		'serviceNUN',
		'serviceSainte',
		'reports',
		'registry',
		'customerDetail',
		'profile',
	],
	technical_support: [
		'reports',
		'customers',
		'profile',
		'products',
		'serviceNUN',
		'serviceSainte',
	],
};

export const rolesDashboardAllowed: RolesPagesAllowed = {
	admin: ['dashboard', 'dashboardSales', 'dashboardFinance'],
	commercial: ['dashboard', 'dashboardSales', 'dashboardFinance'],
	financial: ['dashboard', 'dashboardSales', 'dashboardFinance'],
	super_admin: ['dashboard', 'dashboardSales', 'dashboardFinance'],
	technical_support: [''],
};

export const rolesContentAllowed: RolesPagesAllowed = {
	admin: [
		'dashboard',
		'dashboardSales',
		'dashboardFinance',
		'leads',
		'reports',
		'customers',
		'products',
		'serviceNUN',
		'serviceSainte',
		'billing',
		'customerDetail',
		'profile',
	],
	commercial: [
		'dashboard',
		'dashboardSales',
		'dashboardFinance',
		'leads',
		'reports',
		'customers',
		'products',
		'serviceNUN',
		'serviceSainte',
		'billing',
		'customerDetail',
		'profile',
	],
	financial: [
		'dashboard',
		'dashboardSales',
		'dashboardFinance',
		'leads',
		'reports',
		'customers',
		'products',
		'serviceNUN',
		'serviceSainte',
		'billing',
		'customerDetail',
		'profile',
	],
	super_admin: [
		'dashboard',
		'dashboardSales',
		'dashboardFinance',
		'leads',
		'customers',
		'products',
		'serviceNUN',
		'serviceSainte',
		'billing',
		'reports',
		'registry',
		'customerDetail',
		'profile',
	],
	technical_support: [
		'reports',
		'customers',
		'customerDetail',
		'profile',
		'products',
		'serviceNUN',
		'serviceSainte',
	],
};

export default rolesPagesAllowed;
