import { lazy } from 'react';
import { appPagesMenu, dashboardPagesMenu, profilePage } from '../menu';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	DASHBOARD_SALES: lazy(
		() => import('../pages/presentation/dashboard/dashboard-sales/DashboardSalesPage'),
	),
	DASHBOARD_FINANCE: lazy(
		() => import('../pages/presentation/dashboard/dashboard-finance/DashboardFinancePage'),
	),
};

const APP = {
	REPORTS: lazy(() => import('../pages/presentation/reports/ReportsDashboard')),
	LEADS: lazy(() => import('../pages/presentation/leads/LeadsTable')),
	CUSTOMERS: {
		CUSTOMERS: lazy(() => import('../pages/presentation/customer/CustomerTable')),
		CUSTOMER: lazy(() => import('../pages/presentation/customer/CustomerEditFluid')),
	},
	BILLING: lazy(() => import('../pages/presentation/billing/BillingPage')),
	REGISTRY: lazy(() => import('../pages/presentation/users/UsersTable')),
	PROFILE: lazy(() => import('../pages/presentation/profile/EmployeePage')),
	PRODUCTS: lazy(() => import('../pages/presentation/products/nun/NUNPage')),
	SERVICES_NUN: lazy(() => import('../pages/presentation/products/nun/NUNPage')),
	SERVICES_SAINTE: lazy(() => import('../pages/presentation/products/sainte/SaintePage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		id: 'dashboard',
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		id: 'dashboardSales',
		path: dashboardPagesMenu.dashboard.subMenu.dashboardSales.path,
		element: <LANDING.DASHBOARD_SALES />,
	},
	{
		id: 'dashboardFinance',
		path: dashboardPagesMenu.dashboard.subMenu.dashboardFinance.path,
		element: <LANDING.DASHBOARD_FINANCE />,
	},
	/**
	 * App
	 */
	{
		id: 'leads',
		path: appPagesMenu.leads.path,
		element: <APP.LEADS />,
	},
	{
		id: 'reports',
		path: appPagesMenu.reports.path,
		element: <APP.REPORTS />,
	},
	{
		id: 'customers',
		path: appPagesMenu.customers.path,
		element: <APP.CUSTOMERS.CUSTOMERS />,
	},
	{
		id: 'products',
		path: appPagesMenu.products.path,
		element: <APP.PRODUCTS />,
	},
	{
		id: 'serviceNUN',
		path: appPagesMenu.products.subMenu.serviceNUN.path,
		element: <APP.SERVICES_NUN />,
	},
	{
		id: 'serviceSainte',
		path: appPagesMenu.products.subMenu.serviceSainte.path,
		element: <APP.SERVICES_SAINTE />,
	},
	{
		id: 'billing',
		path: appPagesMenu.billing.path,
		element: <APP.BILLING />,
	},
	{
		id: 'customerDetail',
		path: `${appPagesMenu.customers.path}/:id`,
		element: <APP.CUSTOMERS.CUSTOMER />,
	},
	{
		id: 'registry',
		path: appPagesMenu.registry.path,
		element: <APP.REGISTRY />,
	},
	{
		id: 'profile',
		path: profilePage.profile.path,
		element: <APP.PROFILE />,
	},
];

const contents = [...presentation];

export default contents;
