import axios from 'axios';
import PropTypes from 'prop-types';
import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import avCorpLogo from '../assets/img/logo192.png';
import Avatar from '../components/Avatar';
import Spinner from '../components/bootstrap/Spinner';
import Page from '../layout/Page/Page';
import { IUserAdmins } from '../types/user-type';

export interface IAuthContextProps {
	user: string;
	setUser(user: string): void;
	userData: Partial<IUserAdmins>;
	setUserData(data: Partial<IUserAdmins>): void;
	jwtToken: string;
	setJwtToken(token: string): void;
	csrfToken: string;
	setCsrfToken(token: string): void;
	isSessionActive: boolean;
	setIsSessionActive(active: boolean): void;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('facit_authUsername') || '');
	const [userData, setUserData] = useState<Partial<IUserAdmins>>({});
	const [jwtToken, setJwtToken] = useState<string>('');
	const [csrfToken, setCsrfToken] = useState<string>('');
	const [isSessionActive, setIsSessionActive] = useState<boolean>(true);
	const [readyToRender, setIsReadyToRender] = useState<boolean>(false);

	useEffect(() => {
		localStorage.setItem('facit_authUsername', user);
	}, [user]);

	useEffect(() => {
		const fetchToken = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_LARAVEL_URL}/me`, {
					withCredentials: true,
				});
				const userInfo = JSON.stringify(response.data);
				localStorage.setItem('jwtToken', response.data.api_token);
				localStorage.setItem('csrf', response.data.csrf);
				localStorage.setItem('user', JSON.stringify(response.data));
				setCsrfToken(response.data.csrf);
				setJwtToken(response.data.api_token); // Adjust key as needed
				setUserData(JSON.parse(userInfo)); // Set user data
				setIsReadyToRender(true);
			} catch (error) {
				setJwtToken(''); // Reset token on error
				setUserData({});
				setCsrfToken('');
				setIsReadyToRender(false);
				window.location.href = `${process.env.REACT_APP_LARAVEL_URL}/login`;
			}
		};

		fetchToken();
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
			setUserData,
			jwtToken,
			setJwtToken,
			isSessionActive,
			setIsSessionActive,
			csrfToken,
			setCsrfToken,
		}),
		[user, userData, isSessionActive, jwtToken, csrfToken],
	);

	return (
		<AuthContext.Provider value={value}>
			{readyToRender ? (
				children
			) : (
				<Page>
					<div className='d-flex align-items-center justify-content-center vh-100'>
						<div className='position-relative d-inline-flex align-items-center justify-content-center'>
							<Spinner color='dark' isGrow={false} size={200} />
							<Avatar
								className='position-absolute'
								style={{
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
								}}
								src={avCorpLogo}
								size={190}
								color='info'
							/>
						</div>
					</div>
				</Page>
			)}
		</AuthContext.Provider>
	);
};

AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
