import { useContext } from 'react';
import AuthContext from '../contexts/authContext';
import { rolesContentAllowed } from '../pages/_layout/_asides/util/RolesPagesAllowed';
import contents from '../routes/contentRoutes'; // Ensure this is correctly imported

// This interface allows any string to be used as a key.
interface IRolesPagesAllowed {
	[role: string]: string[];
}

// Ensure that rolesPagesAllowed conforms to IRolesPagesAllowed
// If rolesPagesAllowed has specific known keys, you could define those in a type for better autocompletion
const rolesPagesAllowedTyped: IRolesPagesAllowed = rolesContentAllowed;

export const useRoleBasedRoutes = () => {
	const { userData } = useContext(AuthContext);

	// Assuming userData.roles is an array of objects with a pivot property that has admin_role_id
	// The filter method is updated to ensure only non-null strings are included
	const userRoles = (userData?.roles?.map((role) => role.pivot.admin_role_id) ?? []).filter(
		(role): role is string => typeof role === 'string',
	);

	// Use the typed rolesPagesAllowed for better type checking
	return contents.filter(({ id }) =>
		userRoles.some((role) => rolesPagesAllowedTyped[role]?.includes(id)),
	);
};

// Note: Make sure to import the presentation array correctly from where it's defined.
